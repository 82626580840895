import { FirebaseApp, initializeApp } from "firebase/app";
import { Analytics, getAnalytics } from "firebase/analytics";
import { getFirestore, Firestore } from "firebase/firestore";
import { getAuth, Auth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAJl6wL3TVKixy97UoKAoEeUwMefQioJzo",
  authDomain: "pagadiario-dev.firebaseapp.com",
  projectId: "pagadiario-dev",
  storageBucket: "pagadiario-dev.appspot.com",
  messagingSenderId: "578173845779",
  appId: "1:578173845779:web:9097bb55dcc956b5c71ae6",
  measurementId: "G-Z57J462LC5",
};

let _app: FirebaseApp, _analytics: Analytics, _db: Firestore, _auth: Auth;

function app() {
  if (!_app) {
    _app = initializeApp(firebaseConfig);
  }
  return _app;
}

function analytics() {
  if (!_analytics) {
    _analytics = getAnalytics(app());
  }
  return _analytics;
}

function db() {
  if (!_db) {
    _db = getFirestore(app());
  }
  return _db;
}

function auth() {
  if (!_auth) {
    _auth = getAuth(app());
  }
  return _auth;
}

export {
  app as getApp,
  analytics as getAnalytics,
  db as getDb,
  auth as getAuth,
};
