import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/auth",
    name: "auth",
    component: () => import("@/layouts/authLayout.vue"),
    children: [
      {
        path: "login",
        name: "login",
        component: () => import("@/auth/authView.vue"),
      },
    ],
  },
  {
    path: "/",
    name: "app",
    component: () => import("@/layouts/appLayout/appLayout.vue"),
    children: [
      {
        path: "users",
        name: "users",
        component: () => import("@/users/usersView.vue"),
      },
      {
        path: "businesses",
        name: "businesses",
        component: () => import("@/businesses/businessesView.vue"),
      },
      {
        path: "business/:id",
        name: "business",
        component: () => import("@/businesses/businessView.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
